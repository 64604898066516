import styled from 'styled-components';
import PropTypes from 'prop-types';
import { themeGet } from 'styled-system';

import WhiteCheck from './white-check.svg';
import RedCheck from './red-check.svg';

function StepperButton(props) {
  const {
    value, title, completed, active, onClick, disabled,
  } = props;
  const isSelected = value === active;

  // TODO: This is gross, need to get svg fill working
  const renderText = () => {
    if (completed) {
      return <CheckImage src={isSelected ? WhiteCheck : RedCheck} />;
    }
    return <CounterText>{value}</CounterText>;
  };

  return (
    <CounterWrapper>
      <div>
        <CounterButton
          value={value}
          completed={completed}
          isSelected={isSelected}
          onClick={() => onClick(value)}
          disabled={disabled}
        >
          {renderText()}
        </CounterButton>
      </div>
      <CounterDescription>{title}</CounterDescription>
    </CounterWrapper>
  );
}

const CounterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 55px;
`;

const getBorderColor = (props) => {
  if (props.completed || props.isSelected) {
    return themeGet('colors.tertiary');
  }
  return themeGet('colors.textColor');
};

const getTextColor = (props) => {
  if (props.completed) {
    return themeGet('colors.tertiary');
  } if (props.isSelected) {
    return themeGet('colors.secondary');
  }
  return themeGet('colors.textColor');
};

const CounterButton = styled.button`
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 55px;
  height: 55px;
  transition: all 0.5s ease;
  text-decoration: none;
  margin-bottom: 10px;
  border: 2px solid ${props => getBorderColor(props)};
  color: ${props => getTextColor(props)};
  background-color: ${props => (props.isSelected ? themeGet('colors.tertiary') : themeGet('colors.secondary'))};
  &:focus {
    outline: none;
  }
  &:hover:not([disabled]){
    cursor: pointer;
  }
`;

const CounterText = styled.span`
  font-size: 1.5rem;
  font-family: ${themeGet('fonts.sans')};
`;

const CounterDescription = styled.div`
  text-transform: uppercase;
  margin: 0;
  font-size: 10px;
  letter-spacing: 1px;
  text-align: center;
  width: 120px;
  color: ${themeGet('colors.primary')};
  font-family: ${themeGet('fonts.caecilia')};
  @media (max-width: 650px) {
    width: 70px;
  }
`;

const CheckImage = styled.img`
  width: 50%;
`;

StepperButton.propTypes = {
  /** Value of individual step to be displayed inside step button, can be string or number */
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  /** Optional title to be displayed underneath individual step  button */
  title: PropTypes.string,
  /** Optional boolean for setting initial state of button as completed */
  completed: PropTypes.bool,
  /** Optional onClick function to be called when individual step button is clicked */
  onClick: PropTypes.func,
  /** Passed from Stepper Group via initialStep prop to determine which
   *  button is currently active */
  active: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  /** Optional disabled prop will prevent stepper button from being clicked */
  disabled: PropTypes.bool,
};

StepperButton.defaultProps = {
  title: '',
  completed: false,
};

export default StepperButton;
