import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {
  space, display, flexDirection, justifyContent, alignItems, width, flex, flexWrap,
} from 'styled-system';

class SegmentGroup extends React.Component {
  constructor(props) {
    super(props);
    const { defaultValue } = this.props;
    this.state = { selected: defaultValue };
    this.handleSegmentClicked = this.handleSegmentClicked.bind(this);
    this.isSelected = this.isSelected.bind(this);
    this.renderChildren = this.renderChildren.bind(this);
  }

  handleSegmentClicked(event) {
    const { onChange } = this.props;
    const { target: { value } } = event;
    this.setState({ selected: value });
    if (onChange) {
      onChange(event);
    }
  }

  isSelected(childValue) {
    const { selected } = this.state;
    return selected === childValue;
  }

  renderChildren() {
    const { children } = this.props;
    // Add additional props onto child components
    return children.map(child => React.cloneElement(child, {
      onClick: this.handleSegmentClicked,
      selected: this.isSelected(child.props.value),
    }));
  }

  render() {
    return (
      <StyledSegmentGroup {...this.props}>
        {this.renderChildren()}
      </StyledSegmentGroup>
    );
  }
}

const StyledSegmentGroup = styled.div(
  space,
  display,
  flexDirection,
  justifyContent,
  width,
  alignItems,
  flex,
  flexWrap,
);

StyledSegmentGroup.propTypes = {
  ...display.propTypes,
  ...flexDirection.propTypes,
  ...justifyContent.propTypes,
  ...space.porpTypes,
};

SegmentGroup.defaultProps = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  p: '12px 0 0 0',
};

SegmentGroup.propTypes = {
  /** Segment button elements - best practice is to use SegmentButton components  */
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
  /** Defines segment to be selected by default */
  defaultValue: PropTypes.string,
  /** Function called when a segment is selected */
  onChange: PropTypes.func,
};

export default SegmentGroup;
