import styled from 'styled-components';
import { themed } from '../themeHelper';
import Text from '../Text/Text';

export const Heading = styled(Text)(
  themed('Heading'),
);

Heading.defaultProps = {
  as: 'h2',
  m: 0,
  fontSize: 3,
  color: 'textColor',
  fontFamily: 'sans',
  fontWeight: 'bold',
};

Heading.displayName = 'Heading';

export default Heading;
