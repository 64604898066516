import styled from 'styled-components';
import PropTypes from 'prop-types';
import {
  fontFamily,
  fontSize,
  borders,
  borderColor,
  space,
  width,
  height,
  themeGet,
  color,
} from 'styled-system';

const StyledSegmentButton = styled.button`
  text-transform: capitalize;
  /* Add white color if selected or use props color if not selected */
  color: ${(props) => {
    const colorFn = props.selected ? themeGet('colors.secondary', '#FFFFFF') : themeGet(`colors.${props.color}`, '#004F71');
    return colorFn(props);
  }};

  /* Add primary color if selected or use props color if not selected */
  background-color: ${(props) => {
    const backgroundColorFn = props.selected ? themeGet('colors.primary', '#004F71') : themeGet(`colors.${props.bg}`, '#FFFFFF');
    return backgroundColorFn(props);
  }};
  
  ${fontFamily}
  ${fontSize}
  ${borders}
  ${borderColor}
  ${space}
  ${height}
  ${width}

  &:focus {
    outline: none;
  }

  &:last-child {
    border-right: 1px solid ${themeGet('colors.primary', '#004F71')};
  }
  
  &:hover {
    cursor:${props => (props.disabled ? 'not-allowed' : 'pointer')};
  }
`;

const SegmentButton = props => <StyledSegmentButton {...props} />;

SegmentButton.propTypes = {
  ...fontFamily.propTypes,
  ...fontSize.propTypes,
  ...borders.propTypes,
  ...borderColor.propTypes,
  ...space.propTypes,
  ...width.propTypes,
  ...height.propTypes,
  ...color.propTypes,
  /** Value of individual segment */
  value: PropTypes.string.isRequired,
  /** Disables individual segment if set to true */
  disabled: PropTypes.bool,
};

SegmentButton.defaultProps = {
  fontFamily: 'sans',
  fontSize: '15px',
  border: '1px solid',
  borderColor: 'primary',
  mr: '-4px',
  borderRight: 'none',
  width: '100px',
  height: '40px',
  bg: 'secondary',
  color: 'primary',
  disabled: false,
};

export default SegmentButton;
