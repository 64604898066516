import styled from 'styled-components';
import PropTypes from 'prop-types';
import {
  display,
  fontWeight,
  fontFamily,
  borders,
  color,
  borderColor,
  borderRadius,
  space,
  fontSize,
  width,
  maxWidth,
  height,
  textAlign,
} from 'styled-system';
import { themed } from '../themeHelper';

const Input = styled.input`
  box-sizing: border-box;
  appearance: none;

  &::-webkit-inner-spin-button, ::-webkit-outer-spin-button { 
    opacity: 1;
  }

  &:focus{ 
    outline: none
  }

  ${display}
  ${space}
  ${width}
  ${maxWidth}
  ${height}
  ${textAlign}
  ${color}
  ${fontSize}
  ${fontWeight}
  ${fontFamily}
  ${borders}
  ${borderColor}
  ${borderRadius}
  ${themed('Input')}
`;

Input.propTypes = {
  ...display.propTypes,
  ...textAlign.propTypes,
  ...space.propTypes,
  ...width.propTypes,
  ...maxWidth.propTypes,
  ...height.propTypes,
  ...color.propTypes,
  ...fontSize.propTypes,
  ...fontWeight.propTypes,
  ...fontFamily.propTypes,
  ...borders.propTypes,
  ...borderColor.propTypes,
  ...borderRadius.propTypes,
  /** Text displayed in input field before user starts typing */
  placeholder: PropTypes.string,
  /** Function called typing in input field */
  onChange: PropTypes.func,
};

Input.defaultProps = {
  as: 'input',
  fontSize: 2,
  fontFamily: 'sans',
  m: '10px',
  p: '1px 1px 1px 12px',
  height: '50px',
  color: 'textColor',
  bg: 'white',
  border: 1,
  borderColor: '#aaaaaa',
  borderRadius: 0,
};

export default Input;
