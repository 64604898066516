import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  fontWeight,
  fontFamily,
  borders,
  borderColor,
  borderRadius,
  buttonStyle,
  textAlign,
  lineHeight,
  alignSelf,
  variant,
} from 'styled-system';
import Box from '../Box/Box';
import { themed } from '../themeHelper';

const Button = styled(Box)({
  appearance: 'none',
  display: 'inline-block',
  textDecoration: 'none',
  '&:focus': { outline: 'none' },
},
fontWeight,
fontFamily,
borders,
borderColor,
borderRadius,
textAlign,
lineHeight,
buttonStyle,
alignSelf,
buttonStyle,
variant,
themed('Button'));

Button.propTypes = {
  /** Disables individual segment if set to true */
  disabled: PropTypes.bool,
  ...fontWeight.propTypes,
  ...borders.propTypes,
  ...borderColor.propTypes,
  ...borderRadius.propTypes,
  /** In order to get disabled styling - set the variant prop to disabled `variant='disabled'` */
  ...buttonStyle.propTypes,
  ...textAlign.propTypes,
  ...lineHeight.propTypes,
};

Button.defaultProps = {
  as: 'button',
  fontSize: 'inherit',
  fontFamily: 'sans',
  m: 0,
  p: 2,
  color: 'white',
  bg: 'primary',
  border: '1px solid',
  borderColor: 'primary',
  borderRadius: 0,
  textAlign: 'center',
  lineHeight: 'inherit',
  disabled: false,
  variant: 'primary',
};

export default Button;
