import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {
  width, height, display, flexDirection, space, fontFamily, color,
} from 'styled-system';
import Box from '../Box/Box';

class RadioGroup extends React.Component {
  constructor(props) {
    super(props);
    const { defaultValue } = this.props;
    this.state = { selected: defaultValue };
    this.handleRadioClicked = this.handleRadioClicked.bind(this);
    this.isSelected = this.isSelected.bind(this);
    this.renderChildren = this.renderChildren.bind(this);
  }

  handleRadioClicked(event) {
    const { onChange } = this.props;
    const { target: { value } } = event;
    this.setState({ selected: value });
    if (onChange) {
      onChange(event);
    }
  }

  isSelected(value) {
    const { selected } = this.state;
    return selected === value;
  }

  renderChildren() {
    const { children } = this.props;
    // Add additional props onto child components
    return children.map(child => React.cloneElement(child, {
      onChange: this.handleRadioClicked,
      checked: this.isSelected(child.props.value),
      id: child.props.value,
    }));
  }

  render() {
    return (
      <StyledRadioGroup>
        {this.renderChildren()}
      </StyledRadioGroup>
    );
  }
}

const StyledRadioGroup = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
},
width,
height,
display,
flexDirection,
space,
fontFamily,
color);

RadioGroup.propTypes = {
  /** optional function to be called when selection changes */
  onChange: PropTypes.func,
  /** array of RadioButton elements */
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
  /** string value for selected RadioButton/child */
  defaultValue: PropTypes.string,
};

RadioGroup.defaultProps = {
  as: 'radio',
  display: 'flex',
  flexDirection: 'column',
  margin: '1em',
  fontFamily: 'sans',
  color: 'primary',
};

export default RadioGroup;
