import styled from 'styled-components';
import {
  borders,
  borderColor,
  borderRadius,
} from 'styled-system';
import { themed } from '../themeHelper';
import Text from '../Text/Text';

const SectionHeader = styled(Text)(
  borders,
  borderColor,
  borderRadius,
  themed('SectionHeader'),
);

SectionHeader.defaultProps = {
  as: 'h3',
  p: '0 0 10px 0',
  mt: '1px',
  color: 'textColor',
  fontFamily: 'sans',
  fontWeight: 'bold',
  borderBottom: '1px solid lightgray',
};

SectionHeader.displayName = 'SectionHeader';

export default SectionHeader;
