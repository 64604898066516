// const theme = {
//   primary: '#004F71',
//   secondary: '#ffffff',
//   textColor: '#5B6770',
//   fontFamily: 'Apercu, sans-serif',
// };

// export default theme;

export const theme = {
  breakpoints: [600, 768, 900, 1024],
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  fontSizes: [12, 14, 15, 16, 20, 24, 36, 48, 80, 96],
  fontWeights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
  lineHeights: {
    solid: 1,
    title: 1.25,
    copy: 1.5,
  },
  letterSpacings: {
    normal: 'normal',
    tracked: '0.1em',
    tight: '-0.05em',
    mega: '0.25em',
  },
  fonts: {
    sans: 'Apercu, sans-serif',
    caecilia: 'Caecilia, sans-serif',
  },
  borders: [
    0,
    '1px solid',
    '2px solid',
    '4px solid',
    '8px solid',
    '16px solid',
    '32px solid',
  ],
  radii: [0, 2, 4, 16, 9999, '100%'],
  width: [16, 32, 64, 128, 256],
  heights: [16, 32, 64, 128, 256],
  maxWidths: [16, 32, 64, 128, 256, 512, 768, 1024, 1536],
  cards: {
    pop: {
      padding: '32px',
      borderRadius: '8px',
      backgroundColor: 'magenta',
      boxShadow: '0 0 48px rgba(0, 0, 0, .25)'
    }
  },
  colors: {
    primary: '#004F71',
    secondary: '#ffffff',
    tertiary: '#dd0031',
    textColor: '#5B6770',
    black: '#000',
    white: '#fff',
    transparent: 'transparent',
    outline: '#aaaaaa',
  },
  Button: {
    "&:disabled": {
      color: "#5B6770",
      backgroundColor: "#e5e0e0",
      border: "1px solid #e5e0e0",
      cursor: "initial"
    }
  },
  buttons: {
    primary: {
      "&:hover:enabled": {
        color: "#004F71",
        backgroundColor: "white",
        cursor: 'pointer'
      },
    },
    transparent: {
      backgroundColor: 'rgba(0, 0, 0, 0)',
      border: 'none',
      cursor: 'pointer',
      borderRadius: '2px',
      '&:hover:enabled': {
        backgroundColor: 'rgba(0,0,0,0.2)',
      },
    },
    danger: {
      color:'white',
      backgroundColor:'#DD0031',
      border: '1px solid #DD0031',
      cursor: 'initial',
      '&:hover:enabled': {
        backgroundColor: 'white',
        color: '#DD0031',
        cursor: 'pointer'
      },
    },
    secondary: {
      color: '#004F71',
      backgroundColor: 'white',
      '&:hover:enabled': {
        color: 'white',
        backgroundColor: '#004F71',
        cursor: 'pointer'
      },
    }
  },
};

