import styled from 'styled-components';
import {
  space,
  color,
  width,
  flex,
  order,
  alignSelf,
  flexWrap,
  flexDirection,
  alignItems,
  justifyContent,
  borders,
  borderRadius,
  borderColor,
  lineHeight,
  fontWeight,
} from 'styled-system';
import { themed } from '../themeHelper';
import Box from '../Box/Box';


const Flex = styled(Box)({
  display: 'flex',
},
space,
color,
width,
flex,
order,
alignSelf,
flexWrap,
flexDirection,
alignItems,
justifyContent,
borders,
borderRadius,
borderColor,
lineHeight,
fontWeight,
themed('Flex'));

Flex.propTypes = {
  ...space.propTypes,
  ...color.propTypes,
  ...width.propTypes,
  ...flex.propTypes,
  ...order.propTypes,
  ...alignSelf.propTypes,
  ...flexWrap.propTypes,
  ...flexDirection.propTypes,
  ...alignItems.propTypes,
  ...justifyContent.propTypes,
  ...borders.propTypes,
  ...borderRadius.propTypes,
  ...borderColor.propTypes,
  ...lineHeight.propTypes,
  ...fontWeight.propTypes,
};

Flex.defaultProps = {
  display: 'flex',
};

export default Flex;
