import styled from 'styled-components';
import {
  space,
  color,
  width,
  display,
  flex,
  order,
  alignSelf,
  fontSize,
  flexWrap,
  flexDirection,
  alignItems,
  justifyContent,
  fontFamily,
  textAlign,
  borderColor,
  borderRadius,
  borders,
  lineHeight,
  fontWeight,
} from 'styled-system';
import { themed } from '../themeHelper';

const Box = styled('div')({
  boxSizing: 'border-box',
},
space,
display,
width,
fontSize,
color,
flex,
order,
alignSelf,
flexWrap,
flexDirection,
alignItems,
justifyContent,
fontFamily,
textAlign,
borderColor,
borderRadius,
borders,
lineHeight,
fontWeight,
themed('Box'));

Box.propTypes = {
  ...space.propTypes,
  ...display.propTypes,
  ...width.propTypes,
  ...fontSize.propTypes,
  ...color.propTypes,
  ...flex.propTypes,
  ...order.propTypes,
  ...alignSelf.propTypes,
  ...flexWrap.propTypes,
  ...flexDirection.propTypes,
  ...alignItems.propTypes,
  ...justifyContent.propTypes,
  ...fontFamily.propTypes,
  ...textAlign.propTypes,
  ...borderColor.propTypes,
  ...borderRadius.propTypes,
  ...borders.propTypes,
  ...lineHeight.propTypes,
  ...fontWeight.propTypes,
};

export default Box;
