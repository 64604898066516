import styled from 'styled-components';
import PropTypes from 'prop-types';
import {
  position,
  top,
  left,
  right,
  bottom,
  color,
  borderRadius,
  height,
  width,
  themeGet,
} from 'styled-system';

function Toggle(props) {
  const {
    id, onChange, checked, ...remainingProps
  } = props;
  return (
    <StyledToggle {...remainingProps} htmlFor={id}>
      <input
        {...remainingProps}
        id={id}
        className="checkbox"
        type="checkbox"
        onChange={onChange}
        checked={checked}
      />
      <StyledSlider {...remainingProps} />
    </StyledToggle>
  );
}

const StyledSlider = styled.span`
  cursor: pointer;
  transition: .3s;

  ${position}
  ${top}
  ${left}
  ${right}
  ${bottom}
  ${color}
  ${borderRadius}
  
  &:before {
    position: absolute;
    content: '';
    height: 20px;
    width: 20px;
    left: 3px;
    bottom: 3px;
    background-color: white;
    transition: .3s;
    border-radius: 50%;
  }
`;
StyledSlider.defaultProps = {
  position: 'absolute',
  top: '0',
  left: '0',
  right: '0',
  bottom: '0',
  bg: '#ccc',
  borderRadius: '34px',
};

const StyledToggle = styled.label`
  position: relative;
  display: inline-block;
  ${width}
  ${height}

  & input {
    height: 0;
    width: 0;
    visibility: hidden;
  }
  
  & input:checked + span {
    background-color: ${themeGet('colors.primary')};
  }
  
  & input:checked + span:before {
    transform: translateX(26px);
  }
`;

StyledToggle.defaultProps = {
  width: '52px',
  height: '26px',
};

StyledToggle.propTypes = {
  ...width.propTypes,
  ...height.propTypes,
  ...color.propTypes,
};

Toggle.propTypes = {
  /** Function called when toggle is turned on or off */
  onChange: PropTypes.func,
  /** Boolean value that states whether the toggle has been checked */
  checked: PropTypes.bool,
  id: PropTypes.string,
};

export default Toggle;
