import styled from 'styled-components';
import {
  fontWeight,
  fontFamily,
  textAlign,
  lineHeight,
  letterSpacing,
  space,
} from 'styled-system';
import Box from '../Box/Box';
import { themed } from '../themeHelper';

const Text = styled(Box)(
  fontFamily,
  fontWeight,
  textAlign,
  lineHeight,
  letterSpacing,
  space,
  themed('Text'),
);

Text.propTypes = {
  ...fontFamily.propTypes,
  ...fontWeight.propTypes,
  ...textAlign.propTypes,
  ...lineHeight.propTypes,
  ...letterSpacing.propTypes,
  ...space.propTypes,
};

Text.defaultProps = {
  as: 'p',
  fontFamily: 'sans',
  color: 'textColor',
};

Text.displayName = 'Text';

export default Text;
