import React from 'react';
import styled from 'styled-components';
import { themeGet, maxWidth } from 'styled-system';
import PropTypes from 'prop-types';
import { find, pluck, propEq } from 'ramda';

class StepperGroup extends React.Component {
  constructor(props) {
    super(props);
    this.state = { active: props.active };
    this.handleStepClicked = this.handleStepClicked.bind(this);
    this.renderChildren = this.renderChildren.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { active } = this.props;
    if (active !== prevProps.active) {
      /* eslint-disable-next-line */
      this.setState({ active });
    }
  }

  handleStepClicked(step) {
    const { onChange, children } = this.props;
    this.setState({ active: step });
    if (onChange) { onChange(step); }
    find(propEq('value', step))(pluck('props', children)).onClick();
  }

  renderChildren() {
    const { children } = this.props;
    const { active } = this.state;
    // Add additional props onto child components
    return children.map(child => React.cloneElement(child, {
      onClick: this.handleStepClicked,
      active,
    }));
  }

  render() {
    return (
      <StyledStepperGroup>
        <Dots />
        <CounterWrapper>
          {this.renderChildren()}
        </CounterWrapper>
      </StyledStepperGroup>
    );
  }
}

const StyledStepperGroup = styled.div`
  display: flex;
  margin: 3em auto;
  position: relative;
  ${maxWidth}
  @media (max-width: 650px) {
    margin: 2em auto;
  }
`;

const CounterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: end;
  width: 100%;
  z-index: 10;
  justify-content: space-between;
  @media (max-width: 650px) {
    width: 90%;
    margin: 0 auto;
  }
`;

const Dots = styled.div`
  border-style: dotted;
  border-image-source: url(dots.svg);
  border-image-slice: 33% 25%;
  border-image-repeat: round;
  border-width: 0 0 5px 0;
  color: ${themeGet('colors.textColor')};
  width: 100%;
  position: absolute;
  top: 25px;
  @media (max-width: 650px) {
    width: 90%;
    margin-left: 5%;
  }
`;

StyledStepperGroup.defaultProps = {
  maxWidth: '650px',
};

StepperGroup.propTypes = {
  /** Stepper Button elements */
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
  /** Optional onChange function to be called when moving between steps */
  onChange: PropTypes.func,
  /** Optional step to begin on */
  active: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};

export default StepperGroup;
