import { Component } from 'react';
import Downshift from 'downshift';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  fontFamily,
  flex,
  color,
  space,
  fontSize,
  width,
  height,
  textAlign,
  alignItems,
  borders,
  borderColor,
  boxShadow,
  position,
} from 'styled-system';
import Input from '../Input/Input';
import Text from '../Text/Text';
import Box from '../Box/Box';

class AutoComplete extends Component {
  constructor(props) {
    super(props);
    this.state = { inputValue: '', selected: '' };
    this.onInputChange = this.onInputChange.bind(this);
    this.onInputBlur = this.onInputBlur.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  onInputChange(event) {
    const { onInputChange } = this.props;
    this.setState({ inputValue: event.target.value });
    if (onInputChange) {
      onInputChange(event);
    }
  }

  onInputBlur(event) {
    const { onBlur } = this.props;
    event.preventDefault();
    if (onBlur) {
      onBlur(event);
    }
  }

  handleChange(selectedItem) {
    const { onChange } = this.props;
    this.setState({ selected: selectedItem, inputValue: selectedItem });
    if (onChange) {
      onChange(selectedItem);
    }
  }

  render() {
    const {
      placeholder,
      items,
      maxItems,
    } = this.props;
    const { inputValue, selected } = this.state;
    const newItems = inputValue
      ? items.filter(item => item.toLowerCase().includes(inputValue.toLowerCase()))
      : items;

    return (
      <Downshift
        onChange={this.handleChange}
        selectedItem={selected}
      >
        {({
          getInputProps,
          getItemProps,
          isOpen,
          selectedItem,
          highlightedIndex,
          openMenu,
        }) => (
          <div>
            <StyledInput
              className={isOpen && newItems.length > 0 && 'input-open'}
              {...this.props}
              {...getInputProps({
                placeholder,
                value: inputValue,
                onChange: this.onInputChange,
                onBlur: this.onInputBlur,
                onClick: () => openMenu(),
              })}
            />
            { isOpen && newItems.length !== 0
                && (
                <StyledItems {...this.props} maxItems={maxItems} items={newItems}>
                  {newItems.map((item, index) => (
                    <StyledItem
                      key={item}
                      className={highlightedIndex === index && 'selected'}
                      {...getItemProps({ item, index, isSelected: selectedItem === item })}
                    >
                      {item}
                    </StyledItem>
                  ))}
                </StyledItems>
                ) }
          </div>
        )}
      </Downshift>
    );
  }
}

export const StyledInput = styled(Input)`
  &.input-open {
    border-bottom: 0.5px solid #EFEFEF;
  }
  margin: 0;
`;

export const StyledItem = styled(Text)(
  fontFamily,
  color,
  space,
  fontSize,
  height,
  flex,
  textAlign,
  alignItems,
  width,
);

StyledItem.propTypes = {
  ...flex.propTypes,
  ...alignItems.propTypes,
  ...textAlign.propTypes,
  ...fontFamily.propTypes,
  ...fontSize.propTypes,
  ...color.propTypes,
  ...height.propTypes,
  ...space.propTypes,
  ...width.propTypes,
};

StyledItem.defaultProps = {
  as: 'p',
  fontSize: 2,
  fontFamily: 'sans',
  color: 'textColor',
  bg: 'white',
  height: '30px',
  m: 0,
  mb: 1,
  p: '0 16px',
  textAlign: 'left',
  display: 'flex',
  alignItems: 'center',
};

export const StyledItems = styled(Box)`
  ${borders}
  ${borderColor}
  ${boxShadow}
  ${width}

  overflow-y: scroll;
  height: ${(props) => {
    const { items, maxItems } = props;
    const calcHeight = items.length < maxItems ? (items.length * 34) + 14 : (maxItems * 34) + 14;
    return `${calcHeight}px`;
  }};
  
  & .selected {
    cursor: pointer;
    background-color: #f3f2ef;
  }
`;

StyledItems.propTypes = {
  ...borders.propTypes,
  ...borderColor.propTypes,
  ...boxShadow.propTypes,
  ...width.propTypes,
  ...height.propTypes,
};

StyledItems.defaultProps = {
  as: 'div',
  fontSize: 2,
  border: '1px solid #EFEFEF',
  borderTop: 'none',
  boxShadow: '-1px 5px 20px -1px rgba(0,0,0,0.2)',
  fontFamily: 'sans',
  color: 'textColor',
  bg: 'white',
  height: '30px',
  m: 0,
  py: 2,
  textAlign: 'left',
};

AutoComplete.propTypes = {
  ...borders.propTypes,
  ...fontFamily.propTypes,
  ...color.propTypes,
  ...boxShadow.propTypes,
  ...position.propTypes,
  ...height.propTypes,
  ...width.propTypes,
  /** Lightened text that is displayed before the user has typed anything into the input. */
  placeholder: PropTypes.string,
  /** Array of possible options that can be chosen from -
   * this list is used to provide the autocomplete options. */
  items: PropTypes.arrayOf(PropTypes.string).isRequired,
  /** Function that occurs once an autocomplete value has been selected. */
  onChange: PropTypes.func,
  /** Function that occurs once the value in the input field has changed. */
  onInputChange: PropTypes.func,
  /** Function that occurs when the user has clicked outside of the autocomplete form. */
  onBlur: PropTypes.func,
  /** The maximum number of items that can be displayed in the dropdown at a given time -
   * scroll to see more. */
  maxItems: PropTypes.number,
};

AutoComplete.defaultProps = {
  maxItems: 5,
};

export default AutoComplete;
