import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  fontFamily,
  borders,
  color,
  borderColor,
  borderRadius,
  space,
  fontSize,
  width,
  height,
  textAlign,
} from 'styled-system';
import Box from '../Box/Box';

class TextArea extends React.Component {
  constructor(props) {
    super(props);
    const { maxLength } = this.props;
    this.state = { charsRemaining: maxLength };
    this.updateCharCount = this.updateCharCount.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
  }

  updateCharCount(value) {
    const { maxLength } = this.props;
    const currentCharCount = value.length;
    const charsRemaining = (maxLength - currentCharCount);
    if (charsRemaining >= 0) {
      this.setState({ charsRemaining });
    }
  }

  handleOnChange(event) {
    const { onChange } = this.props;
    const { target: { value } } = event;
    this.updateCharCount(value);
    if (onChange) {
      onChange(event);
    }
  }

  render() {
    const { disclaimerMessage, maxLength } = this.props;
    // pass all props down to textarea but override onChange
    const textAreaProps = {
      ...this.props,
      onChange: this.handleOnChange,
    };
    const { charsRemaining } = this.state;
    return (
      <React.Fragment>
        <StyledTextArea {...textAreaProps} />
        { maxLength && <Disclaimer>{`${charsRemaining} ${disclaimerMessage}`}</Disclaimer> }
      </React.Fragment>
    );
  }
}

export const StyledTextArea = styled(Box)({
  appearance: 'none',
  '&:focus': {
    outline: 'none',
  },
  '&::placeholder': {
    color: '#aaaaaa',
  },
},
fontFamily,
borders,
color,
borderRadius,
space,
fontSize,
width,
height,
borderColor);

export const Disclaimer = styled(Box)(
  fontFamily,
  fontSize,
  color,
  textAlign,
  space,
  width,
);

StyledTextArea.propTypes = {
  ...borders.propTypes,
  ...borderColor.propTypes,
  ...borderRadius.propTypes,
  ...fontFamily.propTypes,
  ...fontSize.propTypes,
  ...color.propTypes,
  ...height.propTypes,
  ...space.propTypes,
  ...width.propTypes,
};

StyledTextArea.defaultProps = {
  as: 'textarea',
  fontSize: 2,
  fontFamily: 'sans',
  bg: 'white',
  color: 'textColor',
  height: '75px',
  m: '10px',
  p: '7px 10px 7px 12px',
  border: 1,
  borderColor: '#aaaaaa',
  borderRadius: 0,
  width: 'calc(100% - 20px)',
};

TextArea.propTypes = {
  /** Maximum number of characters allowed in the textarea */
  maxLength: PropTypes.number,
  /** Text displayed in textarea before user starts typing */
  placeholder: PropTypes.string,
  /** Message to be displayed to the right of the number of characters remaining */
  disclaimerMessage: PropTypes.string,
  /** Function that is called when the input into the textarea is changed */
  onChange: PropTypes.func,
};

TextArea.defaultProps = {
  maxLength: null,
  placeholder: '',
  disclaimerMessage: 'Characters Remaining',
};

Disclaimer.propTypes = {
  ...fontFamily.propTypes,
  ...fontSize.propTypes,
  ...color.propTypes,
  ...textAlign.propTypes,
  ...space.propTypes,
  ...width.propTypes,
};

Disclaimer.defaultProps = {
  fontFamily: 'sans',
  fontSize: 0,
  color: 'textColor',
  textAlign: 'right',
  m: '-5px 0 0 10px',
  width: 'calc(100% - 22px)',
};

export default TextArea;
