import { Component } from 'react';
import styled, { keyframes } from 'styled-components';
// import { themed } from '../themeHelper';
import PropTypes from 'prop-types';

const pulse = keyframes`
  0% { box-shadow: 0 0 0 10px rgba(0, 79, 113, 0.5); }
  70% { box-shadow: 0 0 0 10px rgba(0, 79, 113, 0.2); }
  100% { box-shadow: 0 0 0 10px rgba(0, 79, 113, 0.2); }
`;

class Checkbox extends Component {
  constructor(props) {
    super(props);
    this.state = { checkboxClassName: 'checkbox-container' };
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    this.setState({ checkboxClassName: 'checkbox-container pulse' });
    setTimeout(() => {
      this.setState({ checkboxClassName: 'checkbox-container' });
    }, 1500);
  }

  render() {
    const { checkboxClassName } = this.state;
    return (
      <StyledCheckbox>
        <label className={checkboxClassName} htmlFor="checkbox">
          <input
            className="checkbox"
            onClick={this.handleClick}
            id="checkbox"
            type="checkbox"
            {...this.props}
          />
          <span className="checkbox-custom" />
        </label>
      </StyledCheckbox>
    );
  }
}

const StyledCheckbox = styled.div`
  /* The container */
  & .checkbox-container {
    position: relative;
    padding-left: 1.2em;
    margin-bottom: 1em;
    cursor: pointer;
    font-size: 17px;
    user-select: none;
  }

  /* Hide the browser's default checkbox */
  & .checkbox-container input {
    position: absolute;
    opacity: 0;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  & .checkbox-custom {
    position: absolute;
    top: 0;
    left: 0;
    height: 16px;
    width: 16px;
    border: 2px solid rgba(0, 0, 0, 0.54);
    border-radius: 2px;
  }

  /* On mouse-over, add a grey background color */
  & .checkbox-container:hover input ~ .checkbox-custom {
    background-color: rgba(0, 79, 113, 0.2);
  }

  /* On mouse-over, add a grey background color */
  & .checkbox-container:hover {
    box-shadow: 0 0 0 10px rgba(0, 79, 113, 0.2);
    border-radius: 50%;
  }

  /* When the checkbox is checked, add a blue background */
  /* Need to updat to pull from theme when refactoring to rebass format */
  & .checkbox-container input:checked ~ .checkbox-custom {
    background-color: #004F71;
    border: 2px solid #004F71;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  & .checkbox-custom:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Style the checkmark/indicator */
  & .checkbox-container .checkbox-custom:after {
    left: 4.8px;
    top: 1.5px;
    width: 4px;
    height: 9px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
  
  /* Show the checkmark when checked */
  & .checkbox-container input:checked ~ .checkbox-custom:after {
    display: block;
  }

  & .pulse {
    border-radius: 50%;
    box-shadow: 0 0 0 rgb(0, 79, 113, 0.4);
    animation: ${pulse} 1000ms 1 ease-in-out;
  }
`;

Checkbox.propTypes = {
  /** Optional function that is called when the checkbox is clicked */
  onChange: PropTypes.func,
  /** Value for checkbox input */
  value: PropTypes.string.isRequired,
};

export default Checkbox;
