import React, { Component } from 'react';
import styled from 'styled-components';
import lottie from 'lottie-web';
import { width, space } from 'styled-system';
import LoadingData from './loading.json';
import Box from '../Box/Box';

class ChickenSpinner extends Component {
  constructor() {
    super();
    this.loadingRef = React.createRef();
  }

  componentDidMount() {
    lottie.loadAnimation({
      container: this.loadingRef.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: LoadingData,
      rendererSettings: { className: 'initial-load' },
    });
  }

  componentWillUnmount() {
    lottie.destroy();
  }

  render() {
    return (
      <StyledChickenSpinner {...this.props} ref={this.loadingRef} />
    );
  }
}

const StyledChickenSpinner = styled(Box)(
  width,
  space,
);

ChickenSpinner.propTypes = {
  ...width.propTypes,
  ...space.propTypes,
};

ChickenSpinner.defaultProps = {
  width: '100px',
  m: '20px auto',
};

export default ChickenSpinner;
