import styled from 'styled-components';
import {
  borders,
  borderColor,
  borderRadius,
  boxShadow,
  backgroundImage,
  backgroundSize,
  backgroundPosition,
  backgroundRepeat,
  opacity,
  variant,
  display,
  flexDirection,
  justifyContent,
  alignItems,
  textAlign,
} from 'styled-system';
import Box from '../Box/Box';
import { themed } from '../themeHelper';

const cards = variant({ key: 'cards' });

const Card = styled(Box)(
  borders,
  borderColor,
  borderRadius,
  boxShadow,
  backgroundImage,
  backgroundSize,
  backgroundPosition,
  backgroundRepeat,
  opacity,
  cards,
  display,
  flexDirection,
  justifyContent,
  alignItems,
  textAlign,
  themed('Card'),
);

Card.propTypes = {
  ...borders.PropTypes,
  ...borderColor.PropTypes,
  ...borderRadius.PropTypes,
  ...boxShadow.PropTypes,
  ...backgroundImage.PropTypes,
  ...backgroundSize.PropTypes,
  ...backgroundPosition.PropTypes,
  ...backgroundRepeat.PropTypes,
  ...opacity.PropTypes,
  ...cards.PropTypes,
  ...display.PropTypes,
  ...justifyContent.PropTypes,
  ...alignItems.PropTypes,
  ...textAlign.PropTypes,
};

Card.defaultProps = {
  bg: 'white',
  color: 'textColor',
  display: 'flex',
  flexDirection: 'column',
  p: '10px',
  m: '6px',
  width: '270px',
  border: '1px solid',
  borderColor: '#e5e0e0',
  fontFamily: 'sans',
  fontSize: 1,
};

export default Card;
