import styled from 'styled-components';
import {
  space,
  maxWidth,
  color,
  width,
  display,
  flex,
  alignSelf,
  fontSize,
  flexWrap,
  flexDirection,
  alignItems,
  justifyContent,
  fontFamily,
  textAlign,
  borderColor,
  borderRadius,
  borders,
} from 'styled-system';
import Box from '../Box/Box';
import { themed } from '../themeHelper';

export const Section = styled(Box)({
  boxSizing: 'initial',
},
space,
maxWidth,
color,
width,
display,
flex,
alignSelf,
fontSize,
flexWrap,
flexDirection,
alignItems,
justifyContent,
fontFamily,
textAlign,
borderColor,
borderRadius,
borders,
themed('Section'));

Section.propTypes = {
  ...space.propTypes,
  ...maxWidth.propTypes,
  ...display.propTypes,
  ...width.propTypes,
  ...fontSize.propTypes,
  ...color.propTypes,
  ...flex.propTypes,
  ...alignSelf.propTypes,
  ...flexWrap.propTypes,
  ...flexDirection.propTypes,
  ...alignItems.propTypes,
  ...justifyContent.propTypes,
  ...fontFamily.propTypes,
  ...textAlign.propTypes,
  ...borderColor.propTypes,
  ...borderRadius.propTypes,
  ...borders.propTypes,
};

Section.defaultProps = {
  as: 'section',
  p: ['1em', '2em'],
  maxWidth: '950px',
  m: '0 auto',
  fontFamily: 'sans',
};

Section.displayName = 'Section';

export default Section;
