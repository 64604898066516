import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {
  width, height, space, fontFamily, color, themeGet,
} from 'styled-system';

function RadioButton(props) {
  const {
    children, name, id, checked, onChange, value, ...addtlProps
  } = props;
  return (
    <React.Fragment>
      <input
        className="radio"
        type="radio"
        id={value}
        name={name}
        value={value}
        checked={checked}
        onChange={onChange}
        style={{ display: 'none' }}
      />
      <StyledLabel
        className="labelGuy"
        {...addtlProps}
        checked={checked}
        htmlFor={id}
      >
        {children}
      </StyledLabel>
    </React.Fragment>
  );
}

const StyledLabel = styled.label`
  position: relative;
  padding-left: ${props => `calc(${height(props).height} + (${height(props).height} * (3/11)))`};
  cursor: pointer;
  ${height}
  ${space}
  ${fontFamily}
  ${color}
  ${width}
  color: ${themeGet('colors.textColor')};

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 100%;
    
    width: ${props => `calc(${height(props).height} - (${height(props).height} * (2/11)))`};
    height: ${props => `calc(${height(props).height} - (${height(props).height} * (2/11)))`};

    
    border: 1px solid #ddd;
    background-color: ${themeGet('colors.secondary')};
  }

  &:after {
    content: '';
    position: absolute;
    border-radius: 100%;
    transition: all 0.2s ease;
    transform: ${props => (props.checked ? 'scale(1)' : 'scale(0)')};
    width: ${props => `calc(${height(props).height} - (${height(props).height} * (5/11)))`};
    height: ${props => `calc(${height(props).height} - (${height(props).height} * (5/11)))`};
    top: ${props => `calc(${height(props).height} * (2/11))`};
    left: ${props => `calc(${height(props).height} * (2/11))`}; 
    background-color: ${themeGet('colors.primary')};
  }
`;

RadioButton.propTypes = {
  /** Label or Name for radio button */
  children: PropTypes.string,
  /** Value for radio button selection */
  value: PropTypes.string.isRequired,
  /** In order to adjust size you must adjust height as size is determined from that prop */
  height: PropTypes.number,
  onChange: PropTypes.func,
  name: PropTypes.string,
  id: PropTypes.string,
  checked: PropTypes.bool,
};

RadioButton.defaultProps = {
  children: '',
  fontFamily: 'sans',
  m: '5px',
  height: 22,
};

export default RadioButton;
